<template>
  <div id="user-list">
    <!-- app drawer -->
    <distributor-webhook-aside
      v-if="action === 'webhook'"
      v-model="sidebarActive"
      :resource="resource"
      @changed="loadDistributorWebhooks(); action = ''"
    ></distributor-webhook-aside>

    <sync-webhook-types-aside
      v-if="action === 'types'"
      v-model="sidebarActive"
      :resource="resource"
      @changed="loadDistributorWebhooks(); action = ''"
    ></sync-webhook-types-aside>

    <!-- user total card -->
    <v-row class="mb-5">
      <v-col
        v-for="total in totalLocal"
        :key="total.total"
        cols="12"
        sm="6"
        md="3"
      >
        <v-card>
          <v-card-text class="d-flex align-center justify-space-between pa-4">
            <div>
              <h2 class="font-weight-semibold mb-1">
                {{ total.total }}
              </h2>
              <span>{{ total.title }}</span>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- list filters -->
    <v-card>
      <v-row class="px-2 ma-0">
        <v-col
          cols="12"
          sm="6"
        >
          <div class="d-flex flex-wrap">
            <v-btn
              color="primary"
              class="mb-4 me-3"
              :loading="loading"
              @click.stop="addNewResoure"
            >
              <v-icon>{{ icons.mdiPlus }}</v-icon>
              <span>Add New Webhook</span>
            </v-btn>
          </div>
        </v-col>
      </v-row>

      <v-divider class="mt-4"></v-divider>

      <!-- table -->
      <v-data-table
        v-model="selectedRows"
        :headers="tableColumns"
        :items="webhookListTable"
        :options.sync="options"
        :server-items-length="totalListTable"
        :loading="loading"
        show-select
      >
        <template #[`item.channel_id`]="{item}">
          {{ t(item.channel.name) }}
        </template>

        <template #[`item.distributor_id`]="{item}">
          {{ item.distributor.nicename }}
          <b>({{ item.distributor.slug }})</b>
        </template>

        <template #[`item.types`]="{item}">
          <v-chip
            v-for="(wType, index) in item.types"
            v-show="index < sliceCount"
            :key="wType.id"
          >
            {{ wType.name }}
          </v-chip>
          <a
            v-show="item.types.length > 3 && sliceCount < 999"
            @click="sliceCount = 999"
          >
            ({{ item.types.length }})
          </a>
        </template>

        <template #[`item.active`]="{item}">
          <v-chip
            v-if="item.active"
            color="success"
          >
            ACTIVE
          </v-chip>
          <v-chip v-else>
            INACTIVE
          </v-chip>
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{item}">
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                link
                @click="setWebhook(item, 'webhook')"
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiPencil }}
                  </v-icon>
                  <span>Update</span>
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                link
                @click="setWebhook(item, 'types')"
              >
                <v-list-item-title>
                  <span>Update Types</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { t } from '@/plugins/i18n'
import { mdiDotsVertical, mdiPencil, mdiPlus } from '@mdi/js'
import { ref } from '@vue/composition-api'
import DistributorWebhookAside from './DistributorWebhookAside.vue'
import SyncWebhookTypesAside from './SyncWebhookTypesAside.vue'
import useDistributorWebhookList from './useDistributorWebhookList'

// sidebar

export default {
  components: {
    DistributorWebhookAside,
    SyncWebhookTypesAside,
  },
  setup() {
    const {
      webhookListTable,
      tableColumns,
      searchQuery,
      totalListTable,
      loading,
      options,
      totalLocal,
      selectedRows,

      loadDistributorWebhooks,
    } = useDistributorWebhookList()

    const action = ref('')
    const sidebarActive = ref(true)
    const resource = ref(false)

    const setWebhook = (webhook, nextAction = 'webhook') => {
      resource.value = webhook
      action.value = nextAction
      sidebarActive.value = false
      setTimeout(() => {
        sidebarActive.value = true
      })
    }

    const addNewResoure = () => {
      resource.value = null
      sidebarActive.value = false
      action.value = 'webhook'
      setTimeout(() => {
        sidebarActive.value = true
      })
    }

    const sliceCount = ref(3)

    return {
      webhookListTable,
      tableColumns,
      searchQuery,
      totalListTable,

      // categoriesOptions,
      loading,
      options,
      totalLocal,
      action,
      sidebarActive,
      resource,
      selectedRows,
      loadDistributorWebhooks,
      setWebhook,
      addNewResoure,

      t,
      sliceCount,

      // icons
      icons: {
        mdiPlus,
        mdiPencil,
        mdiDotsVertical,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
