var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"user-list"}},[(_vm.action === 'webhook')?_c('distributor-webhook-aside',{attrs:{"resource":_vm.resource},on:{"changed":function($event){_vm.loadDistributorWebhooks(); _vm.action = ''}},model:{value:(_vm.sidebarActive),callback:function ($$v) {_vm.sidebarActive=$$v},expression:"sidebarActive"}}):_vm._e(),(_vm.action === 'types')?_c('sync-webhook-types-aside',{attrs:{"resource":_vm.resource},on:{"changed":function($event){_vm.loadDistributorWebhooks(); _vm.action = ''}},model:{value:(_vm.sidebarActive),callback:function ($$v) {_vm.sidebarActive=$$v},expression:"sidebarActive"}}):_vm._e(),_c('v-row',{staticClass:"mb-5"},_vm._l((_vm.totalLocal),function(total){return _c('v-col',{key:total.total,attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-card',[_c('v-card-text',{staticClass:"d-flex align-center justify-space-between pa-4"},[_c('div',[_c('h2',{staticClass:"font-weight-semibold mb-1"},[_vm._v(" "+_vm._s(total.total)+" ")]),_c('span',[_vm._v(_vm._s(total.title))])])])],1)],1)}),1),_c('v-card',[_c('v-row',{staticClass:"px-2 ma-0"},[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('div',{staticClass:"d-flex flex-wrap"},[_c('v-btn',{staticClass:"mb-4 me-3",attrs:{"color":"primary","loading":_vm.loading},on:{"click":function($event){$event.stopPropagation();return _vm.addNewResoure.apply(null, arguments)}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiPlus))]),_c('span',[_vm._v("Add New Webhook")])],1)],1)])],1),_c('v-divider',{staticClass:"mt-4"}),_c('v-data-table',{attrs:{"headers":_vm.tableColumns,"items":_vm.webhookListTable,"options":_vm.options,"server-items-length":_vm.totalListTable,"loading":_vm.loading,"show-select":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.channel_id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.t(item.channel.name))+" ")]}},{key:"item.distributor_id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.distributor.nicename)+" "),_c('b',[_vm._v("("+_vm._s(item.distributor.slug)+")")])]}},{key:"item.types",fn:function(ref){
var item = ref.item;
return [_vm._l((item.types),function(wType,index){return _c('v-chip',{directives:[{name:"show",rawName:"v-show",value:(index < _vm.sliceCount),expression:"index < sliceCount"}],key:wType.id},[_vm._v(" "+_vm._s(wType.name)+" ")])}),_c('a',{directives:[{name:"show",rawName:"v-show",value:(item.types.length > 3 && _vm.sliceCount < 999),expression:"item.types.length > 3 && sliceCount < 999"}],on:{"click":function($event){_vm.sliceCount = 999}}},[_vm._v(" ("+_vm._s(item.types.length)+") ")])]}},{key:"item.active",fn:function(ref){
var item = ref.item;
return [(item.active)?_c('v-chip',{attrs:{"color":"success"}},[_vm._v(" ACTIVE ")]):_c('v-chip',[_vm._v(" INACTIVE ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiDotsVertical))])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.setWebhook(item, 'webhook')}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiPencil)+" ")]),_c('span',[_vm._v("Update")])],1)],1),_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.setWebhook(item, 'types')}}},[_c('v-list-item-title',[_c('span',[_vm._v("Update Types")])])],1)],1)],1)]}}],null,true),model:{value:(_vm.selectedRows),callback:function ($$v) {_vm.selectedRows=$$v},expression:"selectedRows"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }